<template>
	<tr>
		<td>
			<span>{{ proc.pm_id }}</span>
		</td>
		<td>
			<span>{{ proc.pid }}</span>
		</td>
		<td>
			<span>{{ proc.name }}</span>
		</td>
		<td>
			<span>{{ proc.version }}</span>
		</td>
		<td>
			<span>{{ proc.node_version }}</span>
		</td>
		<td>
			<span>{{ proc.monit.cpu }}%</span>
		</td>
		<td>
			<span>{{ (proc.monit.memory / 1000000).toFixed(0) }}mb</span>
		</td>
		<td>
			<StatusIndicator class="mr-3" v-if="proc.status === 'online'" positive pulse></StatusIndicator>
			<StatusIndicator class="mr-3" v-else negative></StatusIndicator>
			<span>{{ proc.status }}</span>
		</td>
		<td>
			<v-tooltip bottom>
				<template v-slot:activator="{ on, attrs }">
					<v-btn :loading="loadingStopProcess" :disabled="proc.status === 'stopped' || disabledStop" color="error" fab small text @click="processEvent(proc, 'stop')" v-bind="attrs" v-on="on">
						<v-icon>mdi-square</v-icon>
					</v-btn>
				</template>
				<span>Stop {{ proc.name }}</span>
			</v-tooltip>

			<v-tooltip bottom>
				<template v-slot:activator="{ on, attrs }">
					<v-btn :loading="loadingRRProcess" color="blue" fab small text @click="processEvent(proc, 'restart')" v-bind="attrs" v-on="on">
						<v-icon>mdi-restart</v-icon>
					</v-btn>
				</template>
				<span>Restart {{ proc.name }}</span>
			</v-tooltip>

		</td>
	</tr>
</template>

<script>
import { StatusIndicator } from 'vue-status-indicator';
import 'vue-status-indicator/dist/vue-status-indicator.css';

export default {
	name: 'Process',
	
	components: {
		StatusIndicator
	},

	props: ['proc'],

	data() {
		return {
			loadingRRProcess: false,
			loadingStopProcess: false,
			disabledRR: false,
			disabledStop: false,
		}
	},

	methods: {
		async processEvent(proc, event) {
			this.loadingRRProcess = event === 'restart' ? true : false;
			this.disabledRR = !this.loadingRRProcess;

			this.loadingStopProcess = event === 'stop' ? true : false;
			this.disabledStop = !this.loadingStopProcess;

			try {
				const request = await fetch(`${this.$store.state.base_url}/system/process/event`, {
					method: 'POST',
					body: JSON.stringify({
						pid: proc.pm_id,
						event: event,
					}),
					headers: {
						"Content-type" : "application/json; charset=UTF-8",
						"Authorization" : this.$cookies.get('guardian_key_session'),
					}
				});
				
				if (request.status === 204) {
					this.$notify({
						group: 'main',
						type: 'success',
						title: this.$store.state.app_name,
						text: 'Done!'
					});
				}
				else {
					const res = await request.json();

					if (request.status === 401) {
						if (res.error) {
							if (this.$store.state.error_types.includes(res.error)) {
								this.sessionExpiredHandler(res);
							}
							else {
								throw res.error;
							}
						}
					}
				}
			}
			catch (error) {
				this.$notify({
					group: 'main',
					type: 'error',
					title: this.$store.state.app_name,
					text: error.error
				});
			}
			finally {
				this.loadingRRProcess = false;
				this.disabledRR = false;

				this.loadingStopProcess = false;
				this.disabledStop = false;
			}
		},
	},
}
</script>